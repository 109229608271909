import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from "moment";
import { size, map } from "lodash";


const formateDate = (date, format) => {
    const dateFormat = format ? format : "DD MMM Y";
    const date1 = moment(new Date(date)).format(dateFormat);
    return date1;
};
const toLowerCase1 = str => {
    return (
        str === "" || str === undefined ? "" : str.toLowerCase()
    );
};

const Name = (cell) => {
    return cell.value ? cell.value : '';
};
const Phone = (cell) => {
    return cell.value ? cell.value : '';
};
const ClientCode = (cell) => {
    return cell.value ? cell.value : '';
};
const Email = (cell) => {
    return cell.value ? cell.value : '';
};

const Tags = (cell) => {
    return (
        <>
            {map(cell.value, (tag, index) =>
                index < 2 && (
                    <div
                        to="#"
                        className={`text-${getStatusColor(tag)}`}
                        key={`_skill_${cell.value}_${index}`}
                    >
                        {tag}
                    </div>
                )
            )}
            {/* {size(cell.value) > 2 && (
                <Link
                    to="#"
                    className="badge badge-soft-primary font-size-11 m-1"
                    key={"_skill_" + cell.value}
                >
                    {size(cell.value) - 1} + more
                </Link>
            )} */}
        </>
    );
};

function getStatusColor(status) {
    if (status === "Not Started") {
        return "danger"; // red color
    } else if (status === "In Progress") {
        return "info"; // blue color
    } else if (status === "Completed") {
        return "success"; // green color
    } else {
        return ""; // default color if status is not recognized
    }
}

const Projects = (cell) => {
    return cell.value ? cell.value : '';
};

const Img = (cell) => {
    return (
        <>
            {!cell.value ? (
                <div className="avatar-xs">
                    <span className="avatar-title rounded-circle">
                        {console.log("cell", cell.data[0].name)}
                        {cell.data[0].name.charAt(0)}
                    </span>
                </div>
            ) : (
                <div>
                    <img
                        className="rounded-circle avatar-xs"
                        src={cell.value}
                        alt=""
                    />
                </div>
            )}
        </>
    );
};

export {
    Name,
    Email,
    Tags,
    Projects,
    Img,
    Phone,
    ClientCode,
};