import React, { useState } from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
// import './index.css'

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  Form,
  Input,
  InputGroup,
} from "reactstrap";

const UserForm = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    address: '',
    bankAccountNumber: '',
    capitalInvested: '',
    returnsTerms: '',
    bankIfscCode: '',
    nomineeName: '',
    relationshipWithNominee: '',
    nomineeContactNumber: '',
    wantsTradingAndDematAccount: false,
    selfiePhoto: '',
    signatureImage: '',
    cancelledChequeImage: '',
    bankStatement: '',
    bankStatementPassword: '',
  });

  const steps = ['Personal Information', 'Bank Details', 'Nominee Details', 'Additional Documents'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData)
    // Handle form submission logic here
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Form onSubmit={handleSubmit} className="kyc-form">
            <Stepper activeStep={activeStep} alternativeLabel className="stepper">
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {activeStep === 0 && (
              <>
                <div className="mb-3">
                  {/* ...Existing fields */}
                  <Label htmlFor="username">Username</Label>
                  <Input
                    type="text"
                    id="username"
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                  />

                  <Label htmlFor="clientRelationshipManager">Client Relationship Manager Name</Label>
                  <Input
                    type="text"
                    id="clientRelationshipManager"
                    name="clientRelationshipManager"
                    value={formData.clientRelationshipManager}
                    onChange={handleChange}
                  />

                  <Label htmlFor="applicantName">Name of the Applicant</Label>
                  <Input
                    type="text"
                    id="applicantName"
                    name="applicantName"
                    value={formData.applicantName}
                    onChange={handleChange}
                  />

                  {/* ...Existing fields */}
                </div>
              </>
            )}

            {activeStep === 1 && (
              <>
                {/* ...Existing fields */}

                <Label htmlFor="dateOfBirth">Date of Birth</Label>
                <Input
                  type="date"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={handleChange}
                />

                <Label htmlFor="contactNumber">Contact Number</Label>
                <Input
                  type="tel"
                  id="contactNumber"
                  name="contactNumber"
                  value={formData.contactNumber}
                  onChange={handleChange}
                />

                <Label htmlFor="alternateContactNumber">Alternate Contact Number (Optional)</Label>
                <Input
                  type="tel"
                  id="alternateContactNumber"
                  name="alternateContactNumber"
                  value={formData.alternateContactNumber}
                  onChange={handleChange}
                />

                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />

                <Label htmlFor="gender">Gender</Label>
                <Input
                  type="select"
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </Input>

                <Label htmlFor="maritalStatus">Marital Status</Label>
                <Input
                  type="select"
                  id="maritalStatus"
                  name="maritalStatus"
                  value={formData.maritalStatus}
                  onChange={handleChange}
                >
                  <option value="">Select Marital Status</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="widowed">Widowed</option>
                </Input>

                <Label htmlFor="fatherSpouseName">Father/Spouse Name</Label>
                <Input
                  type="text"
                  id="fatherSpouseName"
                  name="fatherSpouseName"
                  value={formData.fatherSpouseName}
                  onChange={handleChange}
                />

                <Label htmlFor="panCardNumber">PAN Card Number</Label>
                <Input
                  type="text"
                  id="panCardNumber"
                  name="panCardNumber"
                  value={formData.panCardNumber}
                  onChange={handleChange}
                />

                <Label htmlFor="aadhaarCardNumber">Aadhaar Card Number</Label>
                <Input
                  type="text"
                  id="aadhaarCardNumber"
                  name="aadhaarCardNumber"
                  value={formData.aadhaarCardNumber}
                  onChange={handleChange}
                />

                {/* ...Existing fields */}
              </>
            )}

            {activeStep === 2 && (
              <>
                {/* ...Existing fields */}

                <Label htmlFor="identityProof">Proof of Identity (PAN Card)</Label>
                <Input
                  type="file"
                  id="identityProof"
                  name="identityProof"
                  onChange={handleFileChange}
                />

                <Label htmlFor="addressProof">Proof of Address (Aadhaar Card)</Label>
                <Input
                  type="file"
                  id="addressProof"
                  name="addressProof"
                  onChange={handleFileChange}
                />

                <Label htmlFor="residentialAddress">Residential or Permanent Address</Label>
                <Input
                  type="textarea"
                  id="residentialAddress"
                  name="residentialAddress"
                  value={formData.residentialAddress}
                  onChange={handleChange}
                />

                <Label htmlFor="stateDistrict">State and District</Label>
                <Input
                  type="text"
                  id="stateDistrict"
                  name="stateDistrict"
                  value={formData.stateDistrict}
                  onChange={handleChange}
                />

                <Label htmlFor="pinCode">PIN Code</Label>
                <Input
                  type="text"
                  id="pinCode"
                  name="pinCode"
                  value={formData.pinCode}
                  onChange={handleChange}
                />

                {/* ...Existing fields */}
              </>
            )}

            {activeStep === 3 && (
              <>
                {/* ...Existing fields */}

                <Label htmlFor="planSelected">Plan Selected</Label>
                <Input
                  type="text"
                  id="planSelected"
                  name="planSelected"
                  value={formData.planSelected}
                  onChange={handleChange}
                />

                <Label htmlFor="referredBy">Referral By</Label>
                <Input
                  type="text"
                  id="referredBy"
                  name="referredBy"
                  value={formData.referredBy}
                  onChange={handleChange}
                />

                <Label htmlFor="referralContactNumber">Referral Contact Number</Label>
                <Input
                  type="tel"
                  id="referralContactNumber"
                  name="referralContactNumber"
                  value={formData.referralContactNumber}
                  onChange={handleChange}
                />

                {/* ...Existing fields */}
              </>
            )}
            <div className="button-container">
              {activeStep > 0 && (
                <button className="btn btn-primary w-md" onClick={handleBack}>Back</button>
              )}

              {activeStep < steps.length - 1 ? (
                <button className="btn btn-primary w-md"
                  //  variant="contained" 
                  onClick={handleNext}>Next</button>
              ) : (
                <button type="submit" className="btn btn-primary w-md">
                  Submit
                </button>
              )}
            </div>
          </Form>
          {/* ...Existing code */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserForm;