import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
// const Localedate = (cell) => {
//     return cell.value ? new Date(cell.value).toLocaleDateString() : '';
// };
const Localedate = (cell) => {
    if (cell.value) {
        const date = new Date(cell.value);
  
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
      
        let hours = date.getHours();
        //const amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = (hours % 12) || 12; // Convert to 12-hour format
        hours = hours.toString().padStart(2, '0');
      
        const minutes = date.getMinutes().toString().padStart(2, '0');
      
        const formattedDate = `${year}-${month}-${day}`;
        const formattedTime = `${hours}:${minutes}`;
        return ( <div>
            <div>{formattedDate}</div>
            <div>{formattedTime}</div>
          </div>);
    } else {
      return '';
    }
  };
  
const DownloadReportAction = (cell) => {
    return cell.value ? (<div className="d-flex gap-3">
        <a

            className="text-success"
            href={cell.value}
            download
        >
            <i className="mdi mdi-download-box font-size-18" id="download" />
            Download
            <UncontrolledTooltip placement="top" target="download">
                Download report
            </UncontrolledTooltip>
        </a>
    </div>) : <></>
}

export {
    Localedate,
    DownloadReportAction
};