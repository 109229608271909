import React, { useEffect, useState, useMemo } from 'react';
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
// import TableContainer from "components/Common/TableContainer";
import CustomTableContainer from 'components/Common/CustomTableContainer';
import { Localedate, DownloadReportAction } from './ReportCol'
import { subscribe, unsubscribe } from "../../event"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Form,
  Alert,
} from "reactstrap";
import './index.css'

const API_URL = process.env.REACT_APP_API_URL;

import refresh from './refresh-svgrepo-com.svg';


const ReportGenerator = (props) => {

  const [selectedOption, setSelectedOption] = useState('Weekly');
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [pageSize, setPageSize] = useState(5);
  const [users, setUsers] = useState({ data: [], count: 0 });
  const itemsPerPage = 10;
  const [totalDataCount, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageOptions = Array.from({ length: totalPages }, (_, i) => i + 1);

  const columns = useMemo(
    () => [
      {
        Header: 'Start date',
        accessor: 'fromDate',
        disableFilters: true,
        Cell: cellProps => {
          return <Localedate {...cellProps} />;
        },
      },
      {
        Header: 'End date',
        accessor: 'toDate',
        disableFilters: true,
        Cell: cellProps => {
          return <Localedate {...cellProps} />;
        },
      },
      {
        Header: 'Created Date',
        accessor: 'createdAt',
        disableFilters: true,
        Cell: cellProps => {
          return <Localedate {...cellProps} />;
        },
      },
      {
        Header: 'Generated By',
        accessor: 'admin_email',
        disableFilters: true,
      },
      {
        Header: 'Action',
        accessor: 'url',
        disableFilters: true,
        Cell: cellProps => {
          return <DownloadReportAction {...cellProps} />;
        },
      },
    ],
    []
  );


  useEffect(() => {
    subscribe('reportgenerated', () => fetchData('ALL', 1, 5)
    )
    return () => {
      unsubscribe('reportgenerated', () => fetchData('ALL', 1, 5))
    }
  }, [])

  useEffect(() => {
    fetchData('ALL', 1, 5);
  }, []);

  const handleOptionChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);

    // Reset custom date range when selecting a different option
    if (selectedValue !== "Custom") {
      setStartDate(); // Use an empty string ('') instead of null
      setEndDate();   // Use an empty string ('') instead of null
    }
  };


  const storedDataString = localStorage.getItem('authUser');

  // Parse the stored data string as JSON
  const storedData = JSON.parse(storedDataString);

  // Now you can access the 'token' property
  const tokenValue = storedData.token;
  //console.log(tokenValue)

  const [todayData, setTodayData] = useState([]);
  const [yesterdayData, setYesterdayData] = useState([]);
  const [thisWeekData, setThisWeekData] = useState([]);
  const [thisMonthData, setThisMonthData] = useState([]);
  const [lastMonthData, setLastMonthData] = useState([]);
  const [thisYearData, setThisYearData] = useState([]);
  const [previousYearData, setPreviousYearData] = useState([]);
  const [alertData, setAlertData] = useState(null);
  const [paginationMetaData, setPaginationMetaData] = useState({
    'TODAY': {
      currentPage: 1,
      totalPages: 0,
      totalCount: 0
    },
    'YESTERDAY': {
      currentPage: 1,
      totalPages: 0,
      totalCount: 0
    },
    'THIS_WEEK': {
      currentPage: 1,
      totalPages: 0,
      totalCount: 0
    },
    'THIS_MONTH': {
      currentPage: 1,
      totalPages: 0,
      totalCount: 0
    },
    'LAST_MONTH': {
      currentPage: 1,
      totalPages: 0,
      totalCount: 0
    },
    'THIS_YEAR': {
      currentPage: 1,
      totalPages: 0,
      totalCount: 0
    },
    'PREVIOUS_YEARS': {
      currentPage: 1,
      totalPages: 0,
      totalCount: 0
    },
  });

  const fetchData = (duration = 'ALL', nextStart, pageSize) => {
    const headers = {
      // Add your headers here
      'Authorization': tokenValue,
      'Content-Type': 'application/json',
    };

    const applicant = 'applicant';
    fetch(`${API_URL}/reports?reportType=${applicant}&skip=${nextStart}&limit=${pageSize}`, {
      method: 'GET', // You can change the method based on your API requirements
      headers: headers,
    })
      .then((response) => response.json())
      .then((responseData) => {
        //setTodayData(responseData.data);
        //console.log(responseData.count)
           
        //setReportData(responseData.count);
        //console.log(responseData.TODAY.data); 
        setTodayData({
          'TODAY': {
            currentPage: 1,
            totalPages: Math.ceil(Number(responseData.count) / pageSize),
            totalCount: responseData.count
          } });
        switch (duration) {
          case 'ALL':
            setTodayData(responseData.data);
            console.log(Number(responseData.count), pageSize, {
              'TODAY': {
                currentPage: 1,
                totalPages: Math.ceil(Number(responseData.count) / pageSize),
                totalCount: responseData.count
              }
            })
            setPaginationMetaData({
              'TODAY': {
                currentPage: 1,
                totalPages: Math.ceil(Number(responseData.count) / pageSize),
                totalCount: responseData.count
              }
            })
          case 'TODAY':
            setTodayData(responseData.data);
            break;
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleRefresh = () => {
    fetchData('ALL', 1, 5);
  };

  const handlePageNav = (goToPage, duration, currentPage) => {
    console.log('handlePageNav', goToPage, duration, currentPage)
    let pageNumber = currentPage;
    switch (goToPage) {
      case 'prev':
        pageNumber -= 1
        break;
      case 'next':
        pageNumber += 1
        break;
      case 'first':
        pageNumber = 1
        break;
      case 'last':
        //pageNumber = totalPages - 1
        pageNumber = Math.ceil(paginationMetaData[duration].totalCount / 5)
        break;

    }

    const nextStart = (pageNumber - 1) * pageSize;
    console.log(275, paginationMetaData, {
      ...paginationMetaData,
      [duration]: {
        ...paginationMetaData[duration],
        currentPage: pageNumber,
      },
    })
    setPaginationMetaData({
      ...paginationMetaData,
      [duration]: {
        ...paginationMetaData[duration],
        currentPage: pageNumber,
      },

    });
    fetchData(duration, nextStart, pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    // setCurrentPage(1); // Reset the current page to 1 when changing the page size
  };

  useEffect(() => {
    // Check if alertData is not null, and set a timeout to clear the alert after 3000ms (3 seconds)
    if (alertData) {
      const timeoutId = setTimeout(() => {
        setAlertData(null);
      }, 3000);

      // Clear the timeout if the component unmounts before the timeout expires
      return () => clearTimeout(timeoutId);
    }
  }, [alertData]);

  const [showTable, setShowTable] = useState(false)
  const handleGenerateReport = () => {
    setShowTable(true)

    if (selectedOption === 'Custom' && (!startDate || !endDate)) {
      //window.alert("Please select both start date and end date for the custom option.");
      setAlertData({ type: 'danger', message: "Please select both start date and end date for the custom option" });
      return;
    }


    const requestData = {
      duration: selectedOption,
      reportType: 'applicant'
    };

    if (selectedOption === 'Custom') {
      const Startdate = new Date(startDate); // Month is zero-based (4 is May)
      const dateString = Startdate.toISOString();
      const Enddate = new Date(endDate); // Month is zero-based (4 is May)
      const enddateString = Enddate.toISOString();
      requestData.fromDate = dateString;
      requestData.toDate = enddateString;
    }
    console.log(requestData);
    const headers = {
      // Add your headers here
      'Authorization': tokenValue,
      'Content-Type': 'application/json',
    };

    fetch(`${API_URL}/report`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestData)
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.text();
      })
      .then((responseData) => {
        //console.log('Raw response:', responseData);
        //alert(responseData)
        setAlertData({ type: 'success', message: responseData });
        // handleSSE(tokenValue, (sseData) => {
        //   // Handle the SSE data received from the server
        //   console.log('SSE Response:', sseData);
        //   setSSEData(sseData); // Update the state with the SSE data
        // });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleStartDateChange = (event) => {
    const startDateValue = event.target.value;
    const today = new Date().toISOString().split('T')[0];

    // Add any validation you need for start date
    if (startDateValue > today) {
      //window.alert("Start date cannot be greater than today's date.");
      setAlertData({ type: 'danger', message: "Start date cannot be greater than today's date." });
      setStartDate(today);
    } else if (endDate && startDateValue > endDate) {
      //window.alert("Start date cannot be greater than end date.");
      setAlertData({ type: 'danger', message: "Start date cannot be greater than end date." });
      setStartDate(endDate);
    } else {
      setStartDate(startDateValue);
    }
  };

  const handleEndDateChange = (event) => {
    const endDateValue = event.target.value;
    const today = new Date().toISOString().split('T')[0];

    // Add any validation you need for end date
    if (endDateValue > today) {
      //window.alert("End date cannot exceed today's date.");
      setAlertData({ type: 'danger', message: "End date cannot exceed today's date." });
      setEndDate(today);
    } else if (startDate && endDateValue < startDate) {
      //window.alert("End date cannot be before the start date.");
      setAlertData({ type: 'danger', message: "End date cannot be before the start date." });
      setEndDate(startDate);
    } else {
      setEndDate(endDateValue);
    }
  };

  const [sseData, setSSEData] = useState(null);
  let eventSource;


  useEffect(() => {
    // Fetch tokenValue from localStorage or props (if available)
    const storedDataString = localStorage.getItem("authUser");
    const storedData = JSON.parse(storedDataString);
    const tokenValue = storedData.token || props.tokenValue; // Use props.tokenValue if available

    if (tokenValue) {
      // Start the SSE connection
      handleSSE(tokenValue);
    }

    // Clean up the SSE connection on component unmount
    return () => {
      if (eventSource) {
        eventSource.close(); // Close the SSE connection if it exists
      }
    };
  }, [props.tokenValue]);

  // Function to handle SSE messages
  const handleSSE = (tokenValue) => {
    eventSource = new EventSource(`${process.env.REACT_APP_API_URL}/events`, {
      headers: {
        Authorization: tokenValue,
      },
    });

    eventSource.onmessage = (event) => {
      // Handle the incoming data
      try {
        if (JSON.parse(event.data).reportInProgress === 'NOT_RUNNING') {
          let a = document.createElement('a')
          a.setAttribute('href', JSON.parse(event.data).url)
          a.setAttribute('download', JSON.parse(event.data).url.split('/').pop())
          a.click()
          a = null
          publish('reportgenerated')
        }

      } catch (error) {

      }
      //setSSEData(JSON.parse(event.data)); // Update the state with the SSE data
      let eventStatus = JSON.parse(event.data);
      if(eventStatus.reportType === 'applicant'){
        setSSEData(JSON.parse(event.data)); // Update the state with the SSE data
        //console.log(eventStatus.reportType)
      }
    };

    eventSource.onerror = (error) => {
      console.error("SSE Error:", error);
      eventSource.close(); // Close the connection on error
    };
  };

 

  return (

    <React.Fragment>

      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Reports" breadcrumbItem="Report" /> */}
          {alertData && (
            <Alert color={alertData.type === 'success' ? 'success' : 'danger'} onClose={() => setAlertData(null)} dismissible="true">
              {alertData.message}
            </Alert>
          )}

          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">

                <CardBody className="pt-4">
                  <select value={selectedOption} onChange={handleOptionChange}>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Quarterly">Quarterly</option>
                    <option value="Bi-annual">Bi-annual</option>
                    <option value="Annual">Annual</option>
                    <option value="Custom">Custom</option>
                  </select>
                  {selectedOption === "Custom" && (
                    <div style={{ margin: '20px' }}>
                      <h3 className="report-header">Select Date</h3>
                      <label className="custom-label">Start Date:</label>
                      <input type="date" value={startDate} onChange={handleStartDateChange} />
                      <br />
                      <label className="custom-label">End Date:</label>
                      <input type="date" value={endDate} onChange={handleEndDateChange} />
                    </div>
                  )}
                </CardBody>
                <div className="mt-4">
                  <button className="btn btn-primary  btn-sm" onClick={handleGenerateReport}>Generate Report</button>
                </div>

              </Card>

              {sseData && Object.keys(sseData).length > 0 ? (
                <div>
                  <Card>
                    <CardBody>

                      Report Status : {sseData.reportInProgress}
                      {sseData.reportInProgress === 'COMPLETED' && sseData.reportType ==="applicant" && (
                        <a href={sseData.url} target="_blank" rel="noopener noreferrer" style={{ marginLeft: "8px" }}>

                          <button className="btn btn-primary btn-sm">Download Report</button>
                        </a>
                      )}
                      {sseData.reportInProgress === 'NOT_RUNNING' && (
                        <a href={sseData.url} target="_blank" rel="noopener noreferrer" style={{ marginLeft: "8px" }}>

                          <button className="btn btn-primary btn-sm">Download Report</button>
                        </a>
                      )}
                    </CardBody>
                  </Card>
                </div>
              ) : null}


            </Col>
            {showTable ? (<Col xl="8">
              <Card className="overflow-hidden">

                <CardBody className="pt-0">
                  <div className="report-header-container">
                    {/* Use the "report-header" class */}
                    {/* <h2>Applicants: Report Data</h2> */}
                    <h3 className="report-header">Applicants: Report Data</h3>
                    {/* Refresh Icon */}
                    <div className="cursor-pointer">
                      {/* <RefreshIcon  onClick={handleRefresh}/> */}
                      {/* <img src={refresh} alt="" onClick={handleRefresh} /> */}
                      <svg
                        fill="#000000"
                        height="24px"
                        width="24px"
                        version="1.1"
                        id="Capa_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 492.883 492.883"
                        xmlSpace="preserve"
                        onClick={handleRefresh}
                      >
                        {/* The actual SVG code */}
                        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                        <g id="SVGRepo_iconCarrier">
                          <g>
                            <g>
                              <path d="M122.941,374.241c-20.1-18.1-34.6-39.8-44.1-63.1c-25.2-61.8-13.4-135.3,35.8-186l45.4,45.4c2.5,2.5,7,0.7,7.6-3 l24.8-162.3c0.4-2.7-1.9-5-4.6-4.6l-162.4,24.8c-3.7,0.6-5.5,5.1-3,7.6l45.5,45.5c-75.1,76.8-87.9,192-38.6,282 c14.8,27.1,35.3,51.9,61.4,72.7c44.4,35.3,99,52.2,153.2,51.1l10.2-66.7C207.441,421.641,159.441,407.241,122.941,374.241z"></path>
                              <path d="M424.941,414.341c75.1-76.8,87.9-192,38.6-282c-14.8-27.1-35.3-51.9-61.4-72.7c-44.4-35.3-99-52.2-153.2-51.1l-10.2,66.7 c46.6-4,94.7,10.4,131.2,43.4c20.1,18.1,34.6,39.8,44.1,63.1c25.2,61.8,13.4,135.3-35.8,186l-45.4-45.4c-2.5-2.5-7-0.7-7.6,3 l-24.8,162.3c-0.4,2.7,1.9,5,4.6,4.6l162.4-24.8c3.7-0.6,5.4-5.1,3-7.6L424.941,414.341z"></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </div>
                  {/* <ul>
                    {reportData.map((entry, i) => (
                      <li key={i}>
                        <a href={entry} >{entry}</a>,
                      </li>
                    ))}
                  </ul> */}
                  {/* <h3>Today</h3> */}
                  <CustomTableContainer
                    columns={columns}
                    data={todayData}
                    isGlobalFilter={false}
                    isAddOptions={false}
                    customPageSize={pageSize}
                    className="custom-header-css"
                    totalCount={paginationMetaData.TODAY.totalCount}
                    isAddUserList={true}
                    handlePageNav={handlePageNav}
                    handlePageSizeChange={handlePageSizeChange} // Add the handlePageSizeChange function
                    currentPage={paginationMetaData.TODAY.currentPage} // Pass the current page
                    pageSize={pageSize} // Pass the page size
                    duration='TODAY'
                    paginationMetaData={paginationMetaData}
                  />
                 
                </CardBody>
              </Card>
            </Col>
            ) :(
              ''
            )}
          </Row>
          <div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportGenerator;
