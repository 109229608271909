import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import TableContainer from "../../../components/Common/TableContainer";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Button,
  Form,
  InputGroup,
  Alert,

} from "reactstrap";
import { get } from '../../../helpers/api_helper'
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  Name, Email, Tags, Projects, Img, Phone, ReferralCount,
  ClientCode
} from "./contactlistCol";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getUsers as onGetUsers,
  addNewUser as onAddNewUser,
  updateUser as onUpdateUser,
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

const API_URL = process.env.REACT_APP_API_URL;
const ContactsList = props => {

  //meta title
  document.title = "User List | Admin Dashboard";
  const history = useHistory();

  const [newLink, setNewLink] = useState("");
  const [modal, setModal] = useState(false);
  const [alertData, setAlertData] = useState(null);

  function getStatusColor(status) {
    if (status === "NOT_STARTED") {
      return "danger"; // red color
    } else if (status === "INTRO_DONE" || status === "PERSONAL_DETAILS_DONE" ||
      status === "IIFL_INFO_DONE") {
      return "warning"; // blue color
    } else if (status === "COMPLETED") {
      return "success"; // green color
    } else {
      return ""; // default color if status is not recognized
    }
  }
  useEffect(() => {
    // Check if alertData is not null, and set a timeout to clear the alert after 3000ms (3 seconds)
    if (alertData) {
      const timeoutId = setTimeout(() => {
        setAlertData(null);
      }, 3000);

      // Clear the timeout if the component unmounts before the timeout expires
      return () => clearTimeout(timeoutId);
    }
  }, [alertData]);
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    let hours = date.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12; // Convert to 12-hour format
    hours = hours.toString().padStart(2, '0');

    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes} ${amOrPm}`;

    return (
      <div>
        <div>{formattedDate}</div>
        <div>{formattedTime}</div>
      </div>
    );
  };



  const [clientCodeFilter, setClientCodeFilter] = useState("");
  const [applicantNameFilter, setApplicantNameFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [phoneNumberFilter, setPhoneFilter] = useState('');
  const [startDateFilter, setStartDateFilter] = useState('');
  const [endDateFilter, setEndDateFilter] = useState('');

  const handleApplicantNameFilterChange = (e) => {
    const filterValue = e.target.value;
    setApplicantNameFilter(filterValue);
  };
  const handleEmailChange = (e) => {
    const filterValue = e.target.value;
    setEmailFilter(filterValue);
  };
  const handlePhoneFilterChange = (e) => {
    const filterValue = e.target.value;
    setPhoneFilter(filterValue);
  };

  const [isEdit, setIsEdit] = useState(false);

  const PhoneCustomFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handlePhoneFilterChange}
        placeholder="Contact Number"
        style={{ width: '140px',textAlign: 'center' }}
      />
    );
  };

  const EmailCustomFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handleEmailChange}
        placeholder="Email"
        style={{ width: '110px', minHeight: '38px', textAlign: 'center'}}
      />
    );
  };

  const ApplicatntCustomFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handleApplicantNameFilterChange}
        placeholder="Full Name"
        style={{ width: '110px',textAlign: 'center' }}
      />
    );
  };

  const CustomFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handleFilterChange}
        placeholder="Client Code"
        style={{ width: '110px',textAlign: 'center' }} />
    );
  };
  const handleFilterChange = (event) => {
    const filterValue = event.target.value;
    setClientCodeFilter(filterValue);
  };


  const CustomDateRangeFilter = ({ column }) => {
    const [selectedDates, setSelectedDates] = useState([]);
    const refComp = useRef(null);

    const clearDateRange = () => {
      if (refComp.current) {
        refComp.current.flatpickr.clear();
      }
      setSelectedDates([]);
      fetchData(0, pageSize, clientCodeFilter, applicantNameFilter, emailFilter, phoneNumberFilter);
    };

    const handleDateChange = (dates) => {
      
      setSelectedDates(dates);

      if (dates.length === 2) {
        const startDate = new Date(dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000)).toISOString();
        const endDate = new Date(dates[1].getTime() - (dates[1].getTimezoneOffset() * 60000)).toISOString();
        //console.log('API called with start:', startDate, 'end:', endDate);
        fetchData(0, pageSize, clientCodeFilter, applicantNameFilter, emailFilter, phoneNumberFilter, startDate, endDate);

      }
     
    };


    return (
      <div style={{ width: 200 }}>
        <InputGroup>
          <Flatpickr
            className="form-control d-block"
            placeholder="select start and end date"
            options={{
              mode: 'range',
              dateFormat: 'Y-m-d',
            }}
            onChange={handleDateChange}
            ref={refComp}
          />
       {selectedDates.length > 0 && (
          <Button color="secondary" style={{ marginLeft: '5px' }} onClick={clearDateRange}>
            X
          </Button>
        )}
        </InputGroup>

      </div>
    );
  };



  const [absoluteSerialNumber, setAbsoluteSerialNumber] = useState(0);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const handleCopyClick = (uid) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_UI_URL}/${uid}/referral`);
    setAlertData({ type: 'success', message: "Referral link copied" });
    setTooltipOpen(true);

    setTimeout(() => {
      setTooltipOpen(false);
    }, 2000); // Hide the message after 2 seconds
  };

  const columns = useMemo(
    () => [
      {
        Header: "SI.No",
        Cell: ({ row }) => {
          // Calculate the absolute serial number
          return <span style={{ display: "block", textAlign: "center" }}>
            {absoluteSerialNumber + row.index}
          </span>
        },
      },
      {
        Header: "Selfie",
        // accessor: "name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps) => (
          <>
            {/* {!cellProps.img ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {cellProps.name.charAt(0)}
                </span>
              </div>
            ) : (
              <div>
                <Link to={`/user/${cellProps.id}`}>
                  <img
                    className="rounded-circle avatar-xs"
                    src={cellProps.img}
                    alt=""
                  />
                </Link>
              </div>
            )} */}

            {!cellProps.img ? (
              <div className="avatar-xs">
                <span className="avatar-title rounded-circle">
                  {/* {cellProps.applicantName.charAt(0)} */}
                  <Link to={`/user/${cellProps.uid}`}>
                    <img
                      className="rounded-circle avatar-xs"
                      src={cellProps.selfieImage}
                      alt=""
                    />
                  </Link>
                </span>
              </div>
            ) : (
              <div>
                <Link to={`/users/${cellProps.uid}`}>
                  <img
                    className="rounded-circle avatar-xs"
                    src={cellProps.applicantName}
                    alt=""
                  />
                </Link>
              </div>
            )}
          </>
        ),
      },
      // {
      //   Header: "Client Code",
      //   accessor: "clientCode",
      //   // disableFilters: true,
      //   filterable: true,
      //   Cell: cellProps => {
      //     // const { clientCode } = cellProps.row.original;
      //     // return (<div> {clientCode}</div>);
      //     return <ClientCode {...cellProps} />;

      //   },
      // },
      {
        Header: "",
        accessor: "clientCode",
        // disableFilters: true,
        filterable: true,
        Filter: CustomFilter, // Use your custom filter component
        Cell: cellProps => {
          // return <ClientCode {...cellProps} />;
          const { uid, clientCode } = cellProps.row.original;
          return (
            <Link to={`/user/${uid}`} className="text-body">
              {clientCode}
            </Link>
          );
        },
      },
      {
        Header: "",
        accessor: "name",
        // disableFilters: true,
        filterable: true,
        Filter: ApplicatntCustomFilter,
        Cell: cellProps => {
          const { uid, applicantName } = cellProps.row.original;
          return (
            <Link to={`/user/${uid}`} className="text-body">
              {applicantName}
            </Link>
          );
        },
      },
      {
        Header: "",
        accessor: "email",
        // disableFilters: true,
        Filter: EmailCustomFilter,
        filterable: true,
        Cell: cellProps => {
          // return <Email {...cellProps} />;
          const { uid, email } = cellProps.row.original;
          return (
            <Link to={`/user/${uid}`} className="text-body">
              {email}
            </Link>
          );
        },
      },
      {
        Header: "",
        accessor: "contactNumber",
        // disableFilters: true,
        filterable: true,
        Filter: PhoneCustomFilter,
        Cell: cellProps => {
          // return <Phone {...cellProps} />;
          const { uid, contactNumber } = cellProps.row.original;
          return (
            <Link to={`/user/${uid}`} className="text-body">
              {contactNumber}
            </Link>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          const { uid, status } = cellProps.row.original;
          return <div className={`text-${getStatusColor(status)}`}
            style={{ fontWeight: "700" }}>
            {status}
          </div>;
        },
      },
      {
        Header: "",
        accessor: "createddate",
        //disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          const { createdAt } = cellProps.row.original;
          const formattedDate = formatDate(createdAt);
          return <div className="text-body">{formattedDate}</div>;
        },
        Filter: CustomDateRangeFilter, // Use your custom date range filter component
      },
      {
        Header: "Timestamp",
        accessor: "updateddate",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          const { updatedAt } = cellProps.row.original;
          const formattedDate = formatDate(updatedAt);
          return <div className="text-body" style={{width:100}}
          >
            {formattedDate}
          </div>;
        },
      },
      {
        Header: "Referral Count",
        accessor: "referralcount",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          const { referralCount } = cellProps.row.original;
          return (<div> {referralCount}</div>);
          //return <ReferralCount {...cellProps} />;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          const { uid, status } = cellProps.row.original;

          return (
            <>

              <div className="d-flex gap-3">
                {/* <Link
                  to="#"
                  className="text-success"
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}
                >
                  <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    View
                  </UncontrolledTooltip>
                </Link> */}
                <Button
                  type="button"
                  color="primary"
                  id="edittooltip"
                  className="btn-sm btn-rounded"
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}
                >
                  <i className="mdi mdi-eye font-size-18"  style={{ marginRight: "5px" }} />
                  View
                  <UncontrolledTooltip
                      placement="top"
                      target="edittooltip"
                      
                    >
                     View
                    </UncontrolledTooltip>
                </Button>
                {/* {status !== 'COMPLETED' ? <></> : (
                  <i className="mdi mdi-content-copy font-size-18" id="deletetooltip" style={{ cursor: "pointer" }}
                    onClick={() => {
                      navigator.clipboard.writeText(`${process.env.REACT_APP_UI_URL}/${uid}/referral`);
                    }} >
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Copy Referral Link
                    </UncontrolledTooltip>

                  </i>
                )} */}
                {status === 'COMPLETED' && (
                  <Button
                    color="primary"
                    size="sm"
                    id="deletetooltip"
                    className="btn-sm btn-rounded"
                    onClick={() => handleCopyClick(uid)}
                    style={{ display: 'inline-flex', alignItems: 'center' }}
                  >
                    <i className="mdi mdi-content-copy font-size-18"  style={{ marginRight: "5px" }} />
                    Copy
                    <UncontrolledTooltip
                      placement="top"
                      target="deletetooltip"
                      
                    >
                      Copy Referral link
                    </UncontrolledTooltip>
                  </Button>
                )}

              </div >
            </>
          );
        },
      },
    ],
    [absoluteSerialNumber]
  );

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    const uid = user.uid;
    history.push(`/user/${uid}`);
  };




  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };
  const onClickCopy = users => {

    setNewLink(newLink);
    toggle();
    //setContact(users);
    //setDeleteModal(true);
  };
  const handleDeleteUser = () => {
    dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = (newLink) => {
    //console.log(newLink)
    setNewLink(newLink);
    setIsEdit(false);
    toggle();
  };


  //Pagination code start here
  const [pageSize, setPageSize] = useState(10);
  const [users, setUsers] = useState({ data: [], count: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalDataCount, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageOptions = Array.from({ length: totalPages }, (_, i) => i + 1);

  // useEffect(() => {
  //   fetchData();
  // }, [currentPage]);

  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    fetchData(start, pageSize, clientCodeFilter, applicantNameFilter, emailFilter, phoneNumberFilter, startDateFilter, endDateFilter); // Pass the clientCodeFilter to fetchData
  }, [currentPage, pageSize, clientCodeFilter, applicantNameFilter, emailFilter, phoneNumberFilter, startDateFilter, endDateFilter]);



  const fetchData = async (start, limit, clientCodeFilter, applicantNameFilter, emailFilter, phoneNumberFilter, startDateFilter, endDateFilter) => { // Updated function signature with filterValue parameter
    try {
      let url = `${process.env.REACT_APP_API_URL}/users?skip=${start}&limit=${limit}`;

      if (clientCodeFilter) {
        url += `&clientCode=${clientCodeFilter}`;
      }
      if (applicantNameFilter) { // Include applicantNameFilter in the URL
        url += `&applicantName=${applicantNameFilter}`;
      }
      if (emailFilter) { // Include applicantNameFilter in the URL
        url += `&email=${emailFilter}`;
      }
      if (phoneNumberFilter) { // Include applicantNameFilter in the URL
        url += `&contactNumber=${phoneNumberFilter}`;
      }
      if (startDateFilter && endDateFilter) {
        url += `&applicantStartDate=${startDateFilter}&applicantEndDate=${endDateFilter}`;
      }

      const response = await fetch(url);
      const responseData = await response.json();
      const { data, count } = responseData;

      setUsers({
        data,
        count,
      });
      setTotalData(count)
      setTotalPages(Math.ceil(totalDataCount / itemsPerPage))
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePageNav = (goToPage) => {
    let pageNumber = currentPage;
    switch (goToPage) {
      case 'prev':
        pageNumber -= 1
        break;
      case 'next':
        pageNumber += 1
        break;
      case 'first':
        pageNumber = 1
        break;
      case 'last':
        pageNumber = Math.ceil(totalDataCount / itemsPerPage)
        break;

    }

    const nextStart = (pageNumber - 1) * pageSize;
    setCurrentPage(pageNumber);
    fetchData(nextStart, pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset the current page to 1 when changing the page size
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [applicantMetrics, setApplicantMetrics] = useState({
    totalApplicantsEnrolled: 0,
    totalApplicantionsPending: 0
  })

  useEffect(() => {
    const storedDataString = localStorage.getItem("authUser");
    const storedData = JSON.parse(storedDataString);
    const tokenValue = storedData.token || props.tokenValue;
    const headers = {
      // Add your headers here
      'Authorization': tokenValue,
      'Content-Type': 'application/json',
    };
    async function getMetrics() {
      fetch(`${API_URL}/metrics`, {
        method: 'GET', // You can change the method based on your API requirements
        headers: headers,
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log(responseData)
          setApplicantMetrics(responseData);

        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    getMetrics()
  }, [])
  useEffect(() => {
    // Calculate the absolute serial number for the first row of the current page
    const startSerialNumber = (currentPage - 1) * pageSize + 1;
    setAbsoluteSerialNumber(startSerialNumber);
    return () => { }
  }, [currentPage, pageSize]);

  const reports = {
    "No.of clients applied": "totalApplicantsEnrolled",
    "No.of applications in progress": "totalApplicantionsPending"

  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
         
          <Row>
            <Col lg="12">

              <Card>
                <CardBody>
                  <Col lg="6" className="align-self-center">
                    <div className="text-lg-center mt-4 mt-lg-0">
                      <Row>
                        {Object.keys(reports).map((report, key) => (
                          <Col xs="6" key={"_col_" + key}>
                            <div style={{ display: 'flex', gap: '16px' }}>
                              <p className="text-muted  mb-2">
                                {report}:
                              </p>
                              <h5 className="mb-0">{applicantMetrics[reports[report]]}</h5>
                            </div>
                          </Col>

                        ))}
                      </Row>
                    </div>
                  </Col>
                  {alertData && (
            <Alert color={alertData.type === 'success' ? 'success' : 'danger'} onClose={() => setAlertData(null)} dismissible="true" style={{ width: '50%' ,alignContent:"center"}}>
              {alertData.message}
            </Alert>
          )}
                  <TableContainer
                    columns={columns}
                    data={users && users.data ? users.data : []}
                    totalCount={totalDataCount}
                    isGlobalFilter={true}
                    isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={pageSize} // Pass the custom page size
                    handlePageNav={handlePageNav}
                    handlePageSizeChange={handlePageSizeChange} // Add the handlePageSizeChange function
                    currentPage={currentPage} // Pass the current page
                    pageSize={pageSize} // Pass the page size
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {"Copy Link"}
                    </ModalHeader>
                    <ModalBody>
                      {/* <Form
                        onSubmit={e => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      > */}
                      <Row form>
                        <Col xs={12}>
                          <Link
                            to="#"
                            className="copy-icon"
                            style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              navigator.clipboard.writeText(newLink);
                              setAlertData({ type: 'success', message: "New Applicant link copied" });
                            }}
                          >
                            {newLink}
                            <i className="mdi mdi-content-copy font-size-18" id="copytooltip" />
                            <UncontrolledTooltip placement="top" target="copytooltip">
                              Copy
                            </UncontrolledTooltip>
                          </Link>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col>
                          <div className="text-end">
                            <button
                              type="submit"
                              className="btn btn-success save-user"
                            >
                              Submit
                            </button>
                          </div>
                        </Col>
                      </Row> */}
                      {/* </Form> */}
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ContactsList);
