import React, { useState } from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
// import './index.css'

import {
    Card,
    Col,
    Container,
    Row,
    CardBody,
    CardTitle,
    Label,
    Button,
    Form,
    Input,
    InputGroup,
} from "reactstrap";

const KycForm = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        address: '',
        bankAccountNumber: '',
        capitalInvested: '',
        returnsTerms: '',
        bankIfscCode: '',
        nomineeName: '',
        relationshipWithNominee: '',
        nomineeContactNumber: '',
        wantsTradingAndDematAccount: false,
        selfiePhoto: '',
        signatureImage: '',
        cancelledChequeImage: '',
        bankStatement: '',
        bankStatementPassword: '',
    });

    const steps = ['Personal Information', 'Bank Details', 'Nominee Details', 'Additional Documents'];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(formData)
        // Handle form submission logic here
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Form onSubmit={handleSubmit} className="kyc-form">
                        <Stepper activeStep={activeStep} alternativeLabel className="stepper">
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper>

                        {activeStep === 0 && (
                            <>
                                <div className="mb-3">
                                    {/* <Label htmlFor="formrow-firstname-Input">First Name</Label> */}
                                    <Label htmlFor="fullName">Full Name</Label>
                                    <Input
                                        type="text"
                                        id="fullName"
                                        name="fullName"
                                        value={formData.fullName}
                                        onChange={handleChange}
                                    />

                                    <Label htmlFor="email">Email</Label>
                                    <Input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />

                                    <Label htmlFor="address">Address</Label>
                                    <Input
                                        type="textarea"
                                        id="address"
                                        name="address"
                                        value={formData.address}
                                        onChange={handleChange}
                                    />
                                </div>
                            </>
                        )}

                        {activeStep === 1 && (
                            <>
                                <Label htmlFor="bankAccountNumber">Bank Account Number</Label>
                                <Input
                                    type="text"
                                    id="bankAccountNumber"
                                    name="bankAccountNumber"
                                    value={formData.bankAccountNumber}
                                    onChange={handleChange}
                                />

                                <Label htmlFor="capitalInvested">Capital Invested (INR) and Returns Terms</Label>
                                <Input
                                    type="text"
                                    id="capitalInvested"
                                    name="capitalInvested"
                                    value={formData.capitalInvested}
                                    onChange={handleChange}
                                />

                                <Label htmlFor="bankIfscCode">Bank IFSC Code</Label>
                                <Input
                                    type="text"
                                    id="bankIfscCode"
                                    name="bankIfscCode"
                                    value={formData.bankIfscCode}
                                    onChange={handleChange}
                                />

                            </>
                        )}

                        {activeStep === 2 && (
                            <>
                                <Label htmlFor="nomineeName">Nominee Name</Label>
                                <Input
                                    type="text"
                                    id="nomineeName"
                                    name="nomineeName"
                                    value={formData.nomineeName}
                                    onChange={handleChange}
                                />

                                <Label htmlFor="relationshipWithNominee">Relationship with Nominee</Label>
                                <Input
                                    type="text"
                                    id="relationshipWithNominee"
                                    name="relationshipWithNominee"
                                    value={formData.relationshipWithNominee}
                                    onChange={handleChange}
                                />

                                <Label htmlFor="nomineeContactNumber">Nominee Contact Number</Label>
                                <Input
                                    type="text"
                                    id="nomineeContactNumber"
                                    name="nomineeContactNumber"
                                    value={formData.nomineeContactNumber}
                                    onChange={handleChange}
                                />
                                {/* <Label htmlFor="wantsTradingAndDematAccount">Do you want to open a Trading and Demat Account with IIFL Securities?</Label> */}
                                <div className="form-check mb-4">
                                    <Input
                                        type="checkbox"
                                        className="form-check-Input"
                                        id="horizontal-customCheck"

                                    />
                                    <Label
                                        className="form-check-label"
                                        htmlFor="horizontal-customCheck"
                                    >
                                        Do you want to open a Trading and Demat Account with IIFL Securities?
                                    </Label>
                                </div>
                                {/* <Input
                                    type="checkbox"
                                    id="wantsTradingAndDematAccount"
                                    name="wantsTradingAndDematAccount"
                                    checked={formData.wantsTradingAndDematAccount}
                                    onChange={handleChange}
                                /> */}
                            </>
                        )}

                        {activeStep === 3 && (
                            <>
                                <Label htmlFor="selfiePhoto">Selfie Photo</Label>
                                <Input
                                    type="file"
                                    id="selfiePhoto"
                                    name="selfiePhoto"
                                    accept="image/*"
                                    onChange={handleChange}
                                />

                                <Label htmlFor="signatureImage">Signature Image</Label>
                                <Input
                                    type="file"
                                    id="signatureImage"
                                    name="signatureImage"
                                    accept="image/*"
                                    onChange={handleChange}
                                />

                                <Label htmlFor="cancelledChequeImage">Cancelled Cheque Image</Label>
                                <Input
                                    type="file"
                                    id="cancelledChequeImage"
                                    name="cancelledChequeImage"
                                    accept="image/*"
                                    onChange={handleChange}
                                />

                                <Label htmlFor="bankStatement">Six Months Bank Statement (PDF)</Label>
                                <Input
                                    type="file"
                                    id="bankStatement"
                                    name="bankStatement"
                                    accept=".pdf"
                                    onChange={handleChange}
                                />

                                <Label htmlFor="bankStatementPassword">Bank Statement Password</Label>
                                <Input
                                    type="password"
                                    id="bankStatementPassword"
                                    name="bankStatementPassword"
                                    value={formData.bankStatementPassword}
                                    onChange={handleChange}
                                />
                            </>
                        )}

                        <div className="button-container">
                            {activeStep > 0 && (
                                <button className="btn btn-primary w-md" onClick={handleBack}>Back</button>
                            )}

                            {activeStep < steps.length - 1 ? (
                                <button className="btn btn-primary w-md"
                                    // variant="contained" 
                                    onClick={handleNext}>Next</button>
                            ) : (
                                <button type="submit" className="btn btn-primary w-md">
                                    Submit
                                </button>
                            )}
                        </div>
                    </Form>

                </Container>
                {/* container-fluid */}
            </div>
        </React.Fragment>
    );
};

export default KycForm;