import axios from 'axios';
import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter, useParams, useHistory } from "react-router-dom";


import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;



const EnquiryDetails = (props) => {
  //meta title
  document.title = "Personal Details";

  const history = useHistory();
  const { id } = useParams();
  const [userProfile, setUserData] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/enquiries/${id}`
        );
        const user = response.data;
        setUserData(user);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
  }, [id]);

 

  return (
    <React.Fragment>
      <div className="page-content">
        <button className="btn btn-light" onClick={() => history.goBack()}>
          Back
        </button>
        {userProfile ? (
          <Container fluid>

            <Row>
              <Col xl="8">
               <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Data</CardTitle>

                    <div className="table-responsive" id="table-container">
                      <Table className="table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row">1. Name :</th>
                            <td>{userProfile?.fullName || 'N/A'}</td>
                          </tr>
                          <tr>
                            <th scope="row">3. Contact Number :</th>
                            <td>{userProfile?.contactNumber || 'N/A'}</td>
                          </tr>
                          <tr>
                            <th scope="row">4. Email :</th>
                            <td>{userProfile?.email || 'N/A'}</td>
                          </tr>

                          <tr>
                            <th scope="row">5. Have you invested in stock markets before?</th>
                            <td>{userProfile?.investedInStockMarketsBefore ? 'Yes' : userProfile?.investedInStockMarketsBefore === false ? 'No' : 'N/A'}</td>
                          </tr>
                          <tr>
                            <th scope="row">6. Do you have IIFL DEMAT and Trading account? :</th>
                            <td>{userProfile?.hasIIFLDematAndTradingAccount ? 'Yes' : userProfile?.hasIIFLDematAndTradingAccount === false ? 'No' : 'N/A'}</td>
                          </tr>
                          <tr>
                            <th scope="row">7. Interested In Trading Or Investment :</th>
                            <td>{userProfile?.interestedInTradingOrInvestment ? 'Yes' : userProfile?.interestedInTradingOrInvestment === false ? 'No' : 'N/A'}</td>
                          </tr>

                          <tr>
                            <th scope="row">8. Reason To Explore Financial Markets :</th>
                            <td>{userProfile?.reasonToExploreFinancialMarkets ?? 'N/A'}</td>
                          </tr>
                          <tr>
                            <th scope="row">9. Know Or Used Algo Trading Methods Before :</th>
                            <td>{userProfile?.knowOrUsedAlgoTradingMethodsBefore ? 'Yes' : userProfile?.knowOrUsedAlgoTradingMethodsBefore === false ? 'No' : 'N/A'}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        ) : (
          <p>User not found</p>
        )}
      </div>
    </React.Fragment>
  );
};


export default withRouter(EnquiryDetails);


