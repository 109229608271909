import axios from 'axios';
import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
// import TableContainer from "../../../components/Common/TableContainer";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Button,
  Form,
} from "reactstrap";
// import { get } from '../../../helpers/api_helper'
import * as Yup from "yup";
import { useFormik } from "formik";


import {

  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

const API_URL = process.env.REACT_APP_API_URL;
const Referral = props => {

  //meta title
  document.title = "Referral List";
  const history = useHistory();

  const storedDataString = localStorage.getItem("authUser");
  const storedData = JSON.parse(storedDataString);
  const tokenValue = storedData.token || props.tokenValue;
  const headers = {
    // Add your headers here
    'Authorization': tokenValue,
    'Content-Type': 'application/json',
  };

  const [newLink, setNewLink] = useState("");
  const [modal, setModal] = useState(false);
  const [userId, setUserId] = useState();
  const [refreshData, setRefreshData] = useState(false);

  function getStatusColor(status) {
    if (status === "NOT_STARTED") {
      return "danger"; // red color
    } else if (status === "INTRO_DONE" || status === "PERSONAL_DETAILS_DONE" ||
      status === "IIFL_INFO_DONE") {
      return "warning"; // blue color
    } else if (status === "COMPLETED") {
      return "success"; // green color
    } else {
      return ""; // default color if status is not recognized
    }
  }


  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    let hours = date.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12; // Convert to 12-hour format
    hours = hours.toString().padStart(2, '0');

    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes} ${amOrPm}`;

    return (
      <div>
        <div>{formattedDate}</div>
        <div>{formattedTime}</div>
      </div>
    );
  };


  const [referralstatus, setReferraltatusFilter] = useState("");
  const [referrerName, setReferrerName] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [phoneNumberFilter, setPhoneFilter] = useState('');
  const [referredName, setReferredByName] = useState('');
  const [referredPlanDetails, setPlanSelectedFilter] = useState('');



  //referredPlanDetails=st&referredName=Ra&referrerName=sa&isReferralSuccessful=pending
  const handlePlanFilterChange = (e) => {
    const filterValue = e.target.value;
    setPlanSelectedFilter(filterValue);
  };
  const handleReferredByName = (e) => {
    const filterValue = e.target.value;
    setReferredByName(filterValue);
  };
  const handleEmailChange = (e) => {
    const filterValue = e.target.value;
    setEmailFilter(filterValue);
  };
  const handlePhoneFilterChange = (e) => {
    const filterValue = e.target.value;
    setPhoneFilter(filterValue);
  };
  const handleReferrerName = (e) => {
    const filterValue = e.target.value;
    setReferrerName(filterValue);
  };

  const [isEdit, setIsEdit] = useState(false);
  const EmailCustomFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handleEmailChange}
        placeholder="Email"
        style={{ width: '110px', minHeight: '38px', textAlign: 'center'}}
      />
    );
  };

  const PhoneCustomFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handlePhoneFilterChange}
        placeholder="Contact Number"
        style={{ width: '140px' }}
      />
      
    );
  };

  const ReferredByNameFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handleReferredByName}
        placeholder="Referred By Name"
        style={{ width: '150px', minHeight: '38px' }}
      />
    );
  };
  const ReferredNameFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handleReferrerName}
        placeholder="Referred Name"
        style={{ width: '150px', minHeight: '38px' }}
      />
    );
  };

  const PlanFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handlePlanFilterChange}
        placeholder="Plan selected"
        style={{ width: '115px' }}
      />
    );
  };

  const referralStatusFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handleFilterChange}
        placeholder="is Referral Success"
        style={{ width: '140px' }} />
    );
  };
  const handleFilterChange = (event) => {
    const filterValue = event.target.value;
    setReferraltatusFilter(filterValue);
  };
  const [absoluteSerialNumber, setAbsoluteSerialNumber] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: "SI.No",
        Cell: ({ row }) => {
          // Calculate the absolute serial number
          return <span style={{ display: "block", textAlign: "center" }}>
          {absoluteSerialNumber + row.index}
        </span>
        },
      },
      {
        Header: "",
        accessor: "applicantName",
        // disableFilters: true,
        Filter: ReferredByNameFilter,
        filterable: true,
        Cell: cellProps => {
          const { referrerName } = cellProps.row.original;
          return (
            <div className="text-body">
              {referrerName}

            </div>
          );
        },
      },

      {
        Header: "",
        accessor: "referredName",
        // disableFilters: true,
        filterable: true,
        Filter: ReferredNameFilter, // Use your custom filter component
        Cell: cellProps => {
          // return <ClientCode {...cellProps} />;
          const { referredName } = cellProps.row.original;
          //console.log(users)
          return (
            <div>{referredName}</div>


          );
        },
      },
      {
        Header: "",
        accessor: "email",
        // disableFilters: true,
        filterable: true,
        Filter: EmailCustomFilter, // Use your custom filter component
        Cell: cellProps => {
          // return <ClientCode {...cellProps} />;
          const { email } = cellProps.row.original;
          //console.log(users)
          return (
            <div>{email}</div>


          );
        },
      },
      {
        Header: "",
        accessor: "contactNumber",
        // disableFilters: true,
        filterable: true,
        Filter: PhoneCustomFilter, // Use your custom filter component
        Cell: cellProps => {
          // return <ClientCode {...cellProps} />;
          const { contactNumber } = cellProps.row.original;
          //console.log(users)
          return (
            <div>{contactNumber}</div>


          );
        },
      },
      {
        Header: "",
        accessor: "referredPlanDetails",
        // disableFilters: true,
        filterable: true,
        Filter: PlanFilter,
        Cell: cellProps => {
          const { referredPlanDetails } = cellProps.row.original;
          return (
            <div className="text-body">
              {referredPlanDetails ? referredPlanDetails : 'N/A'}
            </div>
          );
        },
      },
      {
        Header: "",
        accessor: "status",
        // disableFilters: true,
        filterable: true,
        Filter: referralStatusFilter,
        Cell: cellProps => {
          const { uid, isReferralSuccessful } = cellProps.row.original;
          return <div className={`text-${getStatusColor(status)}`}
          >
            {isReferralSuccessful}
          </div>;
        },
      },

      {
        Header: "Capital Invested",
        accessor: "referredCapitalInvested",
        disableFilters: true,
        filterable: true,

        Cell: cellProps => {
          // return <Phone {...cellProps} />;
          const { referredCapitalInvested } = cellProps.row.original;
          return (
            <div className="text-body">
              {referredCapitalInvested ? referredCapitalInvested : 'N/A'}
            </div>

          );
        },
      },

      {
        Header: "Referral date",
        accessor: "createdAt",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          const { createdAt } = cellProps.row.original;
          const formattedDate = formatDate(createdAt);
          return <div className="text-body"
          >
            {formattedDate}
          </div>;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link> */}
               <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded"
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}
                >
                  <i className="mdi mdi-pencil font-size-18" id="edittooltip" style={{ marginRight: "5px" }} />
                  Edit
                </Button>
            </div>
          );
        },
      },
    ],
    [absoluteSerialNumber]
  );

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    //console.log(user._id)
    setUserId({
      id: user._id,
    });
    //setIsEdit(true);

    toggle();
  };


  //Pagination code start here
  const [pageSize, setPageSize] = useState(10);
  const [users, setUsers] = useState({ data: [], count: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalDataCount, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageOptions = Array.from({ length: totalPages }, (_, i) => i + 1);

  // useEffect(() => {
  //   fetchData();
  // }, [currentPage]);

  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    fetchData(start, pageSize, referredName, referrerName, emailFilter, phoneNumberFilter,referredPlanDetails,referralstatus); // Pass the clientCodeFilter to fetchData
    setRefreshData(false);
  }, [currentPage, pageSize, referredName, referrerName, emailFilter, phoneNumberFilter, referredPlanDetails,referralstatus,refreshData]);



  const fetchData = async (start, limit, referredName, referrerName, emailFilter, phoneNumberFilter,referredPlanDetails,referralstatus) => { // Updated function signature with filterValue parameter
    try {
      let url = `${process.env.REACT_APP_API_URL}/referrals?skip=${start}&limit=${limit}`;

      if (referredName) {
        url += `&referrerName=${referredName}`;
      }
      if (referrerName) { 
        url += `&referredName=${referrerName}`;
      }
      if (emailFilter) { 
        url += `&email=${emailFilter}`;
      }
      if (phoneNumberFilter) { 
        url += `&contactNumber=${phoneNumberFilter}`;
      }
      if (referredPlanDetails) { 
        url += `&referredPlanDetails=${referredPlanDetails}`;
      }
      if (referralstatus) { 
        url += `&isReferralSuccessful=${referralstatus}`;
      }
      

      const response = await fetch(url, {
        method: 'GET',
        headers: headers
      });
      const responseData = await response.json();
      const { data, count } = responseData;
      // console.log(responseData)
      setUsers({
        data,
        count,
      });
      setTotalData(count)
      setTotalPages(Math.ceil(totalDataCount / itemsPerPage))
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePageNav = (goToPage) => {
    let pageNumber = currentPage;
    switch (goToPage) {
      case 'prev':
        pageNumber -= 1
        break;
      case 'next':
        pageNumber += 1
        break;
      case 'first':
        pageNumber = 1
        break;
      case 'last':
        pageNumber = Math.ceil(totalDataCount / itemsPerPage)
        break;

    }

    const nextStart = (pageNumber - 1) * pageSize;
    setCurrentPage(pageNumber);
    fetchData(nextStart, pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset the current page to 1 when changing the page size
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };




  useEffect(() => {
    // Calculate the absolute serial number for the first row of the current page
    const startSerialNumber = (currentPage - 1) * pageSize + 1;
    setAbsoluteSerialNumber(startSerialNumber);
    return () => { }
  }, [currentPage, pageSize]);

  const [selectedOption, setSelectedOption] = useState(''); // Initial state

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const handleSubmit = () => {
    if (selectedOption) {
      // Assuming you have a function called makeApiCall
      updateStatusApi(selectedOption);
    }
  };


  const updateStatusApi = async (newStatus) => {
    //console.log(userId)
    try {
      const response = await axios.patch(
        `${API_URL}/referrals/${userId.id}`,
        { isReferralSuccessful: newStatus },
        { headers: headers }
      );
      const updatedUser = response.data;
      console.log(updatedUser);

      //console.log(users.data)
      setModal(false); // Close the modal after the update
      setRefreshData(true);
      // setUserData(updatedUser);
    } catch (error) {
      console.log(error);
    }
  };

  const [applicantMetrics, setApplicantMetrics] = useState({
    totalApplicantsEnrolled: 0,
  })

  useEffect(() => {
    const storedDataString = localStorage.getItem("authUser");
    const storedData = JSON.parse(storedDataString);
    const tokenValue = storedData.token || props.tokenValue;
    const headers = {
      // Add your headers here
      'Authorization': tokenValue,
      'Content-Type': 'application/json',
    };
    async function getMetrics() {
      fetch(`${API_URL}/referral/metrics`, {
        method: 'GET', // You can change the method based on your API requirements
        headers: headers,
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log(responseData)
          setApplicantMetrics(responseData);

        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    getMetrics()
  }, [])

  const reports = {
    "Total Referrals until now": "totalReferrals",
    "Total Successful referrals": "successfulReferralsCount"

  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
          <Row>
            <Col lg="12">

              <Card>
                <CardBody>
                <Col lg="6" className="align-self-center">
                    <div className="text-lg-center mt-4 mt-lg-0">
                      <Row>
                        {Object.keys(reports).map((report, key) => (
                          <Col xs="6" key={"_col_" + key}>
                            <div style={{ display: 'flex', gap: '16px' }}>
                              <p className="text-muted  mb-2">
                                {report}:
                              </p>
                              <h5 className="mb-0">{applicantMetrics[reports[report]]}</h5>
                            </div>
                          </Col>

                        ))}
                      </Row>
                    </div>
                  </Col>
                  <TableContainer
                    columns={columns}
                    data={users && users.data ? users.data : []}
                    totalCount={totalDataCount}
                    isGlobalFilter={true}

                    // handleUserClick={handleUserClicks}
                    customPageSize={pageSize} // Pass the custom page size
                    handlePageNav={handlePageNav}
                    handlePageSizeChange={handlePageSizeChange} // Add the handlePageSizeChange function
                    currentPage={currentPage} // Pass the current page
                    pageSize={pageSize} // Pass the page size
                    className="custom-header-css"
                  />

                  <Modal isOpen={modal} toggle={toggle}>
                    <ModalHeader toggle={toggle} tag="h4">
                      {"Change Referral Status Success"}
                    </ModalHeader>
                    <ModalBody>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                          handleSubmit();
                          return false;
                        }}
                      >
                        <Row form="true">
                          <Col xs={8}>
                            <Input
                              type="select"
                              id="optionDropdown"
                              value={selectedOption}
                              onChange={handleOptionChange}
                            >
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </Input>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="text-end">
                              <button
                                type="submit"
                                className="btn btn-success save-user"
                              >
                                Submit
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};


export default withRouter(Referral);