import React, { useEffect, useState, useRef, useMemo } from "react";
import { withRouter, useHistory, Link } from "react-router-dom";
import TableContainer from "../../components/Common/TableContainer";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormFeedback,
  UncontrolledTooltip,
  Input,
  Button,
  Form,
} from "reactstrap";


import * as Yup from "yup";
import { useFormik } from "formik";



import {
  deleteUser as onDeleteUser,
} from "store/contacts/actions";
import { isEmpty } from "lodash";

const API_URL = process.env.REACT_APP_API_URL;

const Enquiry = props => {

  //meta title
  document.title = "Enquiry List";
  const history = useHistory();

  const [newLink, setNewLink] = useState("");
  const [modal, setModal] = useState(false);

  const storedDataString = localStorage.getItem("authUser");
  const storedData = JSON.parse(storedDataString);
  const tokenValue = storedData.token || props.tokenValue;
  const headers = {
    // Add your headers here
    'Authorization': tokenValue,
    'Content-Type': 'application/json',
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    let hours = date.getHours();
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = (hours % 12) || 12; // Convert to 12-hour format
    hours = hours.toString().padStart(2, '0');

    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes} ${amOrPm}`;

    return (
      <div>
        <div>{formattedDate}</div>
        <div>{formattedTime}</div>
      </div>
    );
  };
  const [applicantNameFilter, setApplicantNameFilter] = useState('');
  const [emailFilter, setEmailFilter] = useState('');
  const [phoneNumberFilter, setPhoneFilter] = useState('');

  const handleApplicantNameFilterChange = (e) => {
    const filterValue = e.target.value;
    setApplicantNameFilter(filterValue);
  };
  const handleEmailChange = (e) => {
    const filterValue = e.target.value;
    setEmailFilter(filterValue);
  };
  const handlePhoneFilterChange = (e) => {
    const filterValue = e.target.value;
    setPhoneFilter(filterValue);
  };

  const [isEdit, setIsEdit] = useState(false);

  const PhoneCustomFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handlePhoneFilterChange}
        placeholder="Contact Number"
        style={{ width: '140px' }}
      />
    );
  };

  const EmailCustomFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handleEmailChange}
        placeholder="Email"
        style={{ width: '110px', minHeight: '38px' }}
      />
    );
  };

  const ApplicatntCustomFilter = ({ column }) => {

    const { filterValue, setFilter } = column;

    return (
      <Input
        value={filterValue}
        type="search"
        onChange={handleApplicantNameFilterChange}
        placeholder="Name"
        style={{ width: '115px' }}
      />
    );
  };

  const [absoluteSerialNumber, setAbsoluteSerialNumber] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: "SI.No",
        Cell: ({ row }) => {
          // Calculate the absolute serial number
          return <span style={{ display: "block", textAlign: "center" }}>
          {absoluteSerialNumber + row.index}
        </span>
        },
      },
   
      {
         Header: "",
        accessor: "fullName",
        // disableFilters: true,
        filterable: true,
        Filter: ApplicatntCustomFilter,
        Cell: cellProps => {
          const { _id, fullName } = cellProps.row.original;
          return (
            <Link to={`/enquiries/${_id}`} className="text-body">
              {fullName}
            </Link>
          );
        },
      },
      {
        Header: "",
        accessor: "email",
        // disableFilters: true,
        Filter: EmailCustomFilter,
        filterable: true,
        Cell: cellProps => {
          // return <Email {...cellProps} />;
          const { _id, email } = cellProps.row.original;
          return (
            <Link to={`/enquiries/${_id}`} className="text-body">
              {email}
            </Link>
          );
        },
      },
      {
        Header: "",
        accessor: "contactNumber",
        // disableFilters: true,
        filterable: true,
        Filter: PhoneCustomFilter,
        Cell: cellProps => {
          // return <Phone {...cellProps} />;
          const { _id, contactNumber } = cellProps.row.original;
          return (
            <Link to={`/enquiries/${_id}`} className="text-body">
              {contactNumber}
            </Link>
          );
        },
      },
      {
        Header: "Enquiry date",
        accessor: "createdAt",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          const { createdAt } = cellProps.row.original;
          const formattedDate = formatDate(createdAt);
          return <div className="text-body"
          >
            {formattedDate}
          </div>;
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              {/* <Link
                to="#"
                className="text-success"
                onClick={() => {
                  const userData = cellProps.row.original;
                  handleUserClick(userData);
                }}
              >
                <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  View
                </UncontrolledTooltip>
              </Link> */}
               <Button
                  type="button"
                  color="primary"
                  className="btn-sm btn-rounded"
                  style={{ display: 'inline-flex', alignItems: 'center' }}
                  onClick={() => {
                    const userData = cellProps.row.original;
                    handleUserClick(userData);
                  }}
                >
                  <i className="mdi mdi-eye font-size-18" id="edittooltip" style={{ marginRight: "5px" }} />
                  View
                </Button>
              {/* <Link
                to="#"
                className="copy-icon"
                onClick={() => {
                  const userData = cellProps.row.original;
                  onClickCopy(userData);
                }}
              >
                <i className="mdi mdi-content-copy font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Copy
                </UncontrolledTooltip>
              </Link> */}
            </div>
          );
        },
      },
    ],
    [absoluteSerialNumber]
  );

  const toggle = () => {
    setModal(!modal);
  };

  const handleUserClick = arg => {
    const user = arg;
    const uid = user._id;
    history.push(`/enquiries/${uid}`);
  };




  const onClickDelete = users => {
    setContact(users);
    setDeleteModal(true);
  };
  const onClickCopy = users => {

    setNewLink(newLink);
    toggle();
    //setContact(users);
    //setDeleteModal(true);
  };
  const handleDeleteUser = () => {
    dispatch(onDeleteUser(contact));
    onPaginationPageChange(1);
    setDeleteModal(false);
  };

  const handleUserClicks = (newLink) => {
    //console.log(newLink)
    setNewLink(newLink);
    setIsEdit(false);
    toggle();
  };


  //Pagination code start here
  const [pageSize, setPageSize] = useState(10);
  const [users, setUsers] = useState({ data: [], count: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalDataCount, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const pageOptions = Array.from({ length: totalPages }, (_, i) => i + 1);


  useEffect(() => {
    const start = (currentPage - 1) * pageSize;
    fetchData(start, pageSize, applicantNameFilter, emailFilter, phoneNumberFilter); // Pass the clientCodeFilter to fetchData
  }, [currentPage, pageSize, applicantNameFilter, emailFilter, phoneNumberFilter]);



  const fetchData = async (start, limit, applicantNameFilter, emailFilter, phoneNumberFilter) => { // Updated function signature with filterValue parameter
    try {
      let url = `${process.env.REACT_APP_API_URL}/enquiries?skip=${start}&limit=${limit}`;
      if (applicantNameFilter) { // Include applicantNameFilter in the URL
        url += `&fullName=${applicantNameFilter}`;
      }
      if (emailFilter) { // Include applicantNameFilter in the URL
        url += `&email=${emailFilter}`;
      }
      if (phoneNumberFilter) { // Include applicantNameFilter in the URL
        url += `&contactNumber=${phoneNumberFilter}`;
      }

      const response = await fetch(url, {
        method: 'GET',
        headers: headers
      });
      const responseData = await response.json();
      const { data, count } = responseData;

      setUsers({
        data,
        count,
      });
      setTotalData(count)
      setTotalPages(Math.ceil(totalDataCount / itemsPerPage))
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handlePageNav = (goToPage) => {
    let pageNumber = currentPage;
    switch (goToPage) {
      case 'prev':
        pageNumber -= 1
        break;
      case 'next':
        pageNumber += 1
        break;
      case 'first':
        pageNumber = 1
        break;
      case 'last':
        pageNumber = Math.ceil(totalDataCount / itemsPerPage)
        break;

    }

    const nextStart = (pageNumber - 1) * pageSize;
    setCurrentPage(pageNumber);
    fetchData(nextStart, pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1); // Reset the current page to 1 when changing the page size
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [applicantMetrics, setApplicantMetrics] = useState({
    totalApplicantsEnrolled: 0,
  })

  useEffect(() => {
    const storedDataString = localStorage.getItem("authUser");
    const storedData = JSON.parse(storedDataString);
    const tokenValue = storedData.token || props.tokenValue;
    const headers = {
      // Add your headers here
      'Authorization': tokenValue,
      'Content-Type': 'application/json',
    };
    async function getMetrics() {
      fetch(`${API_URL}/enquiry/metrics`, {
        method: 'GET', // You can change the method based on your API requirements
        headers: headers,
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log(responseData)
          setApplicantMetrics(responseData);

        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
    getMetrics()
  }, [])

  useEffect(() => {
    // Calculate the absolute serial number for the first row of the current page
    const startSerialNumber = (currentPage - 1) * pageSize + 1;
    setAbsoluteSerialNumber(startSerialNumber);
    return () => { }
  }, [currentPage, pageSize]);

  const reports = {
    "Total no.of enquiries received": "totalEnquiries",

  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Contacts" breadcrumbItem="User List" /> */}
          <Row>
            <Col lg="12">

              <Card>
                <CardBody>
                <Col lg="6" className="align-self-center">
                    <div className="text-lg-center mt-4 mt-lg-0">
                      <Row>
                        {Object.keys(reports).map((report, key) => (
                          <Col xs="6" key={"_col_" + key}>
                            <div style={{ display: 'flex', gap: '16px' }}>
                              <p className="text-muted  mb-2">
                                {report}:
                              </p>
                              <h5 className="mb-0">{applicantMetrics[reports[report]]}</h5>
                            </div>
                          </Col>

                        ))}
                      </Row>
                    </div>
                  </Col>
                  <TableContainer
                    columns={columns}
                    data={users && users.data ? users.data : []}
                    totalCount={totalDataCount}
                    isGlobalFilter={true}
                    // isAddUserList={true}
                    handleUserClick={handleUserClicks}
                    customPageSize={pageSize} // Pass the custom page size
                    handlePageNav={handlePageNav}
                    handlePageSizeChange={handlePageSizeChange} // Add the handlePageSizeChange function
                    currentPage={currentPage} // Pass the current page
                    pageSize={pageSize} // Pass the page size
                    className="custom-header-css"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Enquiry);